import _ from 'lodash';
import { useEffect, useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import Service from './service';
import services from '../database/services';
import Context from '../context';

function List() {
  const { serviceIds, setServiceIds } = useContext(Context);
  const { setTotalPrice } = useContext(Context);
  const { setUrl } = useContext(Context);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const selectedServices = search.get('selectedServices')?.split(',').map(Number);
    let priceSum = 0;

    if (_.isArray(selectedServices)) {
      setServiceIds(selectedServices);
      services
        .filter((service) => selectedServices.includes(service.id))
        .map((service) => { priceSum += service.price; });
      setTotalPrice(priceSum);
    }
  }, []);

  useEffect(() => {
    if (!_.isNil(serviceIds) && !_.isEmpty(serviceIds) && serviceIds.length > 0) {
      const baseUrl = window.location.origin;
      const selectedServices = '?selectedServices=';
      setUrl(`${baseUrl}${selectedServices}${serviceIds}`);
    }
  }, [serviceIds]);

  return (
    <Grid columns={2} style={{ marginBottom: '1em' }}>
      { services.map((service) => <Service key={service.id} service={service} />) }
    </Grid>
  );
}

export default List;
