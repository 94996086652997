import {
  Image,
  Header,
} from 'semantic-ui-react';
import logo from '../assets/images/logo.png';

function Logo() {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Image src={logo} />
      <Header
        as="h1"
        style={{
          marginLeft: '.5em',
          marginTop: 0,
          padding: 0,
        }}
      >
        {'GrafCloud\'s'}
      </Header>
    </div>
  );
}

export default Logo;
