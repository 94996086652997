import { GridColumn, Header } from 'semantic-ui-react';
import cover from '../assets/images/cover.png';
import Logo from './logo';

function Cover() {
  return (
    <GridColumn
      className="graf-cover"
      mobile={16}
      tablet={6}
      computer={6}
      style={{
        padding: 0,
        margin: 0,
        height: '100vh',
        background: `url(${cover}) center`,
        backgroundSize: 'cover',
        overflow: 'hidden',
      }}
    >

      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#fff',
      }}
      >
        <Logo />
        <br />
        <Header
          as="h1"
          style={{
            padding: 0,
            margin: 0,
            fontWeight: 400,
          }}
        >
          Cloud Management Calculator
        </Header>
      </div>
    </GridColumn>
  );
}

export default Cover;
