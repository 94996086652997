const services = [
  {
    id: 1,
    name: 'Cloud Management Gold 50 Instances',
    sla: '30 Mins',
    price: 3000,
    detail: 'For Production - 7/24 Cloud & OS Monitoring, AWS, GC, Azure, VMware, Metrics (CPU, Memory, Disk, Network) + 2 Service + Backup Follow. Extra Cost for NewRelic will be added. ',
  },
  {
    id: 2,
    name: 'Cloud Management Silver 50 Instances',
    sla: '60 Mins',
    price: 2000,
    detail: 'For Production - 7/24 Cloud & OS Monitoring, AWS, GC, Azure, VMware, Metrics (CPU, Memory, Disk, Network) + 2 Service + Backup Follow. Extra Cost for NewRelic will be added. ',
  },
  {
    id: 3,
    name: 'K8S Management Gold Up to 100 Pods',
    sla: '30 Mins',
    price: 3000,
    detail: 'For Production 7/24 Kubernetes Cluster Monitoring, POD Metrics (CPU, Memory, Network), POD Logs / Error Monitoring. Extra Cost for NewRelic will be added.',
  },
  {
    id: 4,
    name: 'K8S Silver Gold Up to 100 Pods',
    sla: '60 Mins',
    price: 2000,
    detail: 'For Dev/Staging 7/24 Kubernetes Cluster Monitoring, POD Metrics (CPU, Memory, Network), POD Logs / Error Monitoring. Extra Cost for NewRelic will be added.',
  },
  {
    id: 5,
    name: 'Cloud Security Management Gold',
    sla: '30 Mins',
    price: 1500,
    detail: 'For Production Apply Security Rules, WAF on Cloudflare, DDOS Management, Attack Response, 1 Vulnerability Scan Every 6 Months',
  },
  {
    id: 6,
    name: 'Cloud Security Management Silver',
    sla: '60 Mins',
    price: 500,
    detail: 'For Dev/Staging Apply Security Rules, WAF on Cloudflare, DDOS Management, Attack Response, 1 Vulnerability Scan Every 6 Months',
  },
  {
    id: 7,
    name: 'DevOps As a Service (2 Release Per Month, Up to 20 Pipeline)',
    sla: '',
    price: 2500,
    detail: 'Release Management, Pipeline Follow, Fix, Execute',
  },
];

export default services;
