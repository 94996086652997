import { Header } from 'semantic-ui-react';

function Title({ title }) {
  return (
    <Header
      as="h2"
      style={{
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
        paddingTop: 15,
        paddingBottom: 15,
        textAlign: 'center',
      }}
    >
      {title}
    </Header>
  );
}

export default Title;
