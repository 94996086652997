import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalDescription,
  ModalActions,
  Button,
  Input,
  Icon,
  Header,
} from 'semantic-ui-react';
import { useContext } from 'react';
import Context from '../context';

function MessageBox({ trigger }) {
  const { url } = useContext(Context);
  const { openMessageBox, setOpenMessageBox } = useContext(Context);
  return (
    <Modal
      onClose={() => setOpenMessageBox(false)}
      onOpen={() => setOpenMessageBox(true)}
      open={openMessageBox}
      trigger={trigger}
      style={{ maxWidth: 400 }}
    >
      <ModalHeader style={{ textAlign: 'center' }}>Contact Us</ModalHeader>
      <ModalContent image>
        <ModalDescription>
          <Input
            id="url-for-copy"
            fluid
            value={url}
            icon={(
              <Icon
                name="copy"
                link
                onClick={() => {
                  document.getElementById('url-for-copy').select();
                  document.execCommand('copy');
                  // console.log(url);
                }}
              />
            )}
          />
          <Header style={{
            fontWeight: 400,
          }}
          >
            Please send url to:
            <a href="mailto:support@grafclouds.com"> support@grafclouds.com</a>
          </Header>
        </ModalDescription>
      </ModalContent>
      <ModalActions>
        <Button
          size="small"
          circular
          content="Ok"
          labelPosition="right"
          icon="checkmark"
          onClick={() => setOpenMessageBox(false)}
          positive
        />
      </ModalActions>
    </Modal>
  );
}

export default MessageBox;
