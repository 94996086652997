import React, { useState } from 'react';
import './assets/stylesheets/main.css';
import 'semantic-ui-css/semantic.min.css';

import {
  Container,
  Grid,
  GridColumn,
} from 'semantic-ui-react';
import Context from './context';
import Cover from './components/cover';
import Title from './components/title';
import List from './components/list';
import Footer from './components/footer';

function App() {
  const [openMessageBox, setOpenMessageBox] = useState(false);
  const [serviceIds, setServiceIds] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [url, setUrl] = useState('');

  const states = {
    serviceIds,
    setServiceIds,
    totalPrice,
    setTotalPrice,
    url,
    setUrl,
    openMessageBox,
    setOpenMessageBox,
  };

  return (
    <Context.Provider value={states}>
      <Container fluid id="container">
        <Grid columns={2} style={{ margin: 0 }}>

          <Cover />

          <GridColumn
            mobile={16}
            tablet={10}
            computer={10}
            style={{
              maxHeight: '100vh',
              overflowY: 'scroll',
            }}
          >

            <Title title="CloudOps As a Service" />
            <List />
            <Footer />

          </GridColumn>

        </Grid>
      </Container>
    </Context.Provider>
  );
}

export default <App />;
