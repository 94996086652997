import _ from 'lodash';
import {
  Divider,
  Grid,
  GridColumn,
  GridRow,
  Segment,
  Header,
  HeaderSubheader,
  Button,
  Icon,
} from 'semantic-ui-react';
import { useContext } from 'react';
import Context from '../context';

function Service({ service }) {
  const { serviceIds, setServiceIds } = useContext(Context);
  const { totalPrice, setTotalPrice } = useContext(Context);

  return (
    <GridColumn mobile={16} tablet={16} computer={8}>
      <Segment style={{
        minHeight: 180,
        opacity: 1, // if selected opacity .5
        border: 0,
        boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.08)',
      }}
      >
        <Grid columns={2}>

          <GridRow>

            <GridColumn
              mobile={13}
              tablet={13}
              computer={13}
            >
              <Header style={{
                fontFamily: 'Source Sans Pro',
                fontWeight: 600,
              }}
              >
                {service.name}
                <HeaderSubheader style={{ paddingTop: 5 }}>
                  <b>SLA: </b>
                  {_.isNil(service.sla) || _.isEmpty(service.sla) ? '-' : service.sla}
                </HeaderSubheader>
              </Header>
            </GridColumn>

            <GridColumn
              mobile={3}
              tablet={3}
              computer={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {
                serviceIds.includes(service.id)
                  ? (
                    <Button
                      icon
                      color="blue"
                      size="mini"
                      circular
                      onClick={() => {
                        let ids = serviceIds;
                        ids = _.without(ids, service.id);
                        setServiceIds(ids);
                        setTotalPrice(totalPrice - service.price);
                      }}
                    >
                      <Icon name="check" />
                    </Button>
                  )
                  : (
                    <Button
                      icon
                      size="mini"
                      circular
                      onClick={() => {
                        setServiceIds([...serviceIds, service.id]);
                        setTotalPrice(totalPrice + service.price);
                        const audio = document.getElementById('audio');

                        audio.pause();
                        audio.currentTime = 0;
                        audio.play();
                      }}
                    >
                      <Icon name="plus" />
                    </Button>
                  )
              }

              <Header>
                {`$${service.price}`}
              </Header>
            </GridColumn>

          </GridRow>

        </Grid>

        <Divider />

        <Grid>
          <GridRow>
            <GridColumn>
              <Header as="h4">
                <HeaderSubheader>
                  {service.detail}
                </HeaderSubheader>
              </Header>
            </GridColumn>
          </GridRow>
        </Grid>

      </Segment>
    </GridColumn>
  );
}

export default Service;
