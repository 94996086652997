import _ from 'lodash';
import {
  Header,
  Icon,
  Segment,
  Button,
} from 'semantic-ui-react';
import { useContext } from 'react';
import Context from '../context';
import MessageBox from './messageBox';
import audio from '../assets/sounds/tick.wav';

function Footer() {
  const { totalPrice } = useContext(Context);
  return (
    <Segment
      inverted
      style={{
        height: 80,
        position: 'sticky',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        bottom: 0,
      }}
    >
      <Header style={{
        fontFamily: 'Source Sans Pro',
        fontSize: 18,
        fontWeight: 400,
        marginLeft: '1em',
        marginBottom: 0,
        height: 80,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
      }}
      >

        {`Total: $${_.toNumber(totalPrice)}`}

      </Header>

      <MessageBox trigger={(
        <Button
          size="small"
          circular
          disabled={totalPrice <= 0}
          color="green"
          style={{ marginRight: '1em' }}
        >
          <Icon name="send" />
          Contact Us
        </Button>
)}
      />
      <audio src={audio} id="audio" />
    </Segment>
  );
}

export default Footer;
